<template>
	<div
		:class="[
			'badges',
			{
				'badges--column': column,
				'badges--overlap': !column,
			},
		]">
		<div
			class="badges__avatar"
			v-for="organizer in displayedBadges"
			:key="organizer.id">
			<UserAvatar
				:full-name="organizer.name"
				:user-id="organizer.id"
				:key="organizer.id"
				:size="'md'"
				@click="openRemoveAgentsModal"
				:border="true" />
		</div>

		<div
			v-if="organizers.length > maxCount && !column"
			class="badges__count">
			+ {{ organizers.length - maxCount }}
		</div>

		<teleport
			v-if="getEventTrigger"
			to="body">
			<PageChatRemoveAgentOrgChat v-model="showRemoveAgentsModal" />
		</teleport>
	</div>
</template>

<script setup lang="ts">
	const {
		organizers,
		column = false,
		maxCount = 15,
	} = defineProps<{
		organizers: IOrganizerShort[];
		column?: boolean;
		maxCount?: number;
	}>();

	const { activeEventStore } = useStores();
	const { getEventTrigger } = storeToRefs(activeEventStore);

	const displayedBadges = computed(() => (column ? organizers : organizers.slice(0, maxCount)));

	const showRemoveAgentsModal = ref<boolean>(false);
	const openRemoveAgentsModal = (): void => {
		if (!getEventTrigger.value) return;

		showRemoveAgentsModal.value = true;
	};
</script>

<style scoped lang="scss">
	@import "badges";
</style>
