<template>
	<div class="manager-chat-container">
		<PageChatOfferItem
			v-if="event"
			:event-info="event"
			:number="event.number"
			:type="'manager'" />

		<div class="manager-chat-container__dialog">
			<ManagerDialogHistory :messages="messages?.data || []" />
		</div>

		<div class="manager-chat-container__controls">
			<div class="manager-chat-container__button-controls">
				<GenerateAiOrgMessage
					class="manager-chat-container__button"
					@message-generated="onMessageGenerated" />

				<!--                <UploadFile-->
				<!--                    class="manager-chat-container__button"-->
				<!--                    :teleport-id="uploadFilesContentId"-->
				<!--                    @on-select="uploadOrgFile" />-->
			</div>

			<div class="manager-chat-container__textarea">
				<PageChatTextareaSkeleton v-if="isAiGenerateMessage" />

				<Textarea
					v-else
					v-model="org_message"
					:auto-resize="textareaAutoResize"
					class="textarea"
					placeholder="Введите сообщение..." />
			</div>

			<SendMessage
				class="manager-chat-container__send"
				@click="sendMessage"
				:loader="fetchIsPending(sendRoomMessageStatus)"
				:disabled="!org_message || isAiGenerateMessage" />
		</div>

		<UploadFilesTeleportContent :id="uploadFilesContentId" />
	</div>
</template>

<script setup lang="ts">
	import ManagerDialogHistory from "~/components/global/dialog-history/manager-dialog-history.vue";
	import { fetchIsPending } from "~/utils/fetch-is-pending";
	// import { uploadOrgFile } from "~/utils/upload-org-file";

	const { room, event, agent_id } = defineProps<{
		room: IRoom;
		event: IEventAdvanced;
		agent_id: string;
	}>();

	const { generateOrgMessageStore } = useStores();
	const { isAiGenerateMessage } = storeToRefs(generateOrgMessageStore);

	const { org_message, sendMessage, sendRoomMessageStatus, messages, getRoomMessages } = useOrgChat(room.id, agent_id);

	const uploadFilesContentId = useState(`room-${room.id}-files`, () => `upload-files-${room.id}`);

	const onMessageGenerated = (message: string) => {
		org_message.value = message;
	};

	const orgChatBus = useOrgChatBus();

	orgChatBus.on(async (event) => {
		if (event.data.room_id !== room.id) return;

		await getRoomMessages();
	});

	const textareaAutoResize = ref(false);

	onMounted(() => {
		setTimeout(() => (textareaAutoResize.value = true), 500);
	});
</script>

<style lang="scss" scoped>
	@import "manager-chat-container";
</style>
