<template>
	<div
		v-if="getEventTrigger"
		class="add-agent-org-chat">
		<UiButtonPlus
			@click="openModal"
			title="Пригласить агентов" />

		<teleport to="body">
			<Modal
				v-model="showModal"
				class="add-agent-org-chat__modal">
				<ModalContent :modal-title="'Выбор агентов для предложения'">
					<template #modalBody>
						<PageChatOfferItem
							v-if="activeEventStore.getActiveEvent"
							class="add-agent-org-chat__event"
							:event-info="activeEventStore.getActiveEvent"
							:type="'modal'" />

						<Search
							class="mb-24"
							@submit="onSearch" />

						<DataTable
							v-model:selection="selectedAgent"
							:value="farmerAgents?.data"
							dataKey="id"
							class="datatable"
							table-class="table table--checkbox"
							paginator
							lazy
							:rows="agentListLimit"
							paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
							currentPageReportTemplate="На странице: {first} - {last} из {totalRecords}"
							:rowsPerPageOptions="[5, 10, 20, 50]"
							:total-records="farmerAgents?.meta.pagination.total"
							@page="onPageChange">
							<template #header>
								<div class="datatable__filters">
									<UiSelection
										v-model="filterMeCurrentValue"
										class="datatable__filter"
										:clearable="false"
										:list="filterMeOptions"
										:select-label="'name'"
										:icon="'person'"
										@on-value-update="fetchFarmerAgents" />
								</div>
							</template>

							<template #empty> Агенты не найдены</template>

							<Column selectionMode="multiple" />

							<Column
								field="name"
								:style="'min-width: 280px'"
								header="Агент" />

							<Column
								field="org_name"
								:style="'width: 200px'"
								header="Организатор" />

							<Column
								field="status"
								:style="'width: 150px'"
								header="Статус">
								<template #body="{ data: agent }: { data: IAgentFarmer }">
									{{ getAgentStatus(agent) }}
								</template>
							</Column>

							<Column
								field="type"
								:style="'width: 180px'"
								header="Тип агента" />
						</DataTable>
					</template>

					<template #modalControls>
						<UiButton
							v-if="farmerAgents?.data.length"
							:name="'Добавить агентов'"
							:disabled="!selectedAgent.length || fetchIsPending(statusAddingAgents)"
							:loader="fetchIsPending(statusAddingAgents)"
							:loader-size="'14'"
							:size="'md'"
							@click="inviteAgents" />
					</template>
				</ModalContent>
			</Modal>
		</teleport>
	</div>
</template>

<script setup lang="ts">
	import { $useFetch } from "~/composables/useFetch";
	import { api } from "~/api/api";
	import type { DataTablePageEvent } from "primevue/datatable";
	import { fetchIsPending } from "~/utils/fetch-is-pending";
	import { getAgentStatus } from "@/utils/get-agent-status";

	const { activeEventStore, roomStore, modalErrorStore } = useStores();
	const { getRoom } = storeToRefs(roomStore);
	const { getActiveEvent, getEventTrigger } = storeToRefs(activeEventStore);

	const farmerId = computed(() => getActiveEvent.value?.attributes.farmer_guid);

	const showModal = ref<boolean>(false);

	const selectedAgent = ref<IAgentFarmer[]>([]);
	const selectedAgentsId = computed<string[]>(() => selectedAgent.value.map((agent) => agent.id));

	interface IFilterMeOptions {
		name: "Все агенты" | "Мои агенты";
		value: boolean;
	}
	const filterMeOptions = ref<IFilterMeOptions[]>([
		{
			name: "Все агенты",
			value: false,
		},
		{
			name: "Мои агенты",
			value: true,
		},
	]);
	const filterMeCurrentValue = ref<IFilterMeOptions>(filterMeOptions.value[0]);
	const queryFilterMe = computed<string>(() => (filterMeCurrentValue.value.value ? "&filter[me]=true" : ""));

	const agentListPage = ref<number>(1);
	const agentListLimit = ref<number>(10);

	const searchPhrase = ref<string>("");
	const querySearch = computed<string>(() => (searchPhrase.value ? `&filter[agent_name]=${searchPhrase.value}` : ""));

	const resetModal = () => {
		showModal.value = false;
		agentListPage.value = 1;
		selectedAgent.value = [];
	};

	const { data: farmerAgents, refresh: fetchFarmerAgents } = await $useFetch<IApiResponsePayload<IAgentFarmer[], IApiPagination>, TFetchError>(
		() => api.farmerAgents(farmerId.value) + `?page=${agentListPage.value}&limit=${agentListLimit.value}` + querySearch.value + queryFilterMe.value,
		{
			method: "GET",
			immediate: false,
			watch: false,
		},
	);

	const {
		data: responseAddingAgents,
		error: errorAddingAgents,
		refresh: fetchInvite,
		status: statusAddingAgents,
	} = $useFetch<IApiResponsePayload<IApiResponseStatus>, TFetchError>(() => api.roomAgents(getRoom.value?.id), {
		method: "PUT",
		immediate: false,
		watch: false,
		body: {
			agents: selectedAgentsId,
		},
	});

	const onSearch = (phrase: string) => {
		searchPhrase.value = phrase;

		fetchFarmerAgents();
	};

	const onPageChange = (event: DataTablePageEvent) => {
		agentListPage.value = event.page + 1;
		agentListLimit.value = event.rows;
		fetchFarmerAgents();
	};

	const openModal = async () => {
		if (!farmerId.value) {
			modalErrorStore.open("К сожалению, невозможно пригласить агентов к данному событию", true);
			return;
		}

		await fetchFarmerAgents();

		showModal.value = true;
	};

	const inviteAgents = async () => {
		await fetchInvite();

		if (errorAddingAgents.value || !responseAddingAgents.value?.data.result) {
			modalErrorStore.open(errorAddingAgents.value?.message || "Ошибка добавления агентов", true);
			return;
		}

		roomStore.fetchEventRoom(true);
		resetModal();
		modalErrorStore.open("Агенты добавлены");
	};

	watch(
		() => showModal.value,
		(newValue) => {
			if (!newValue) {
				resetModal();
			}
		},
	);
</script>

<style scoped lang="scss">
	@import "add-agent-org-chat";
</style>
