<template>
	<button class="button-plus">
		<NuxtIcon
			name="plus-border"
			class="icon" />
	</button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
	@import "button-plus";
</style>
