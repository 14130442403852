<template>
	<CollapseMenu
		v-model="collapse"
		:class="[
			'chat-manager',
			{
				'chat-manager--unavailable': chatIsUnavailable || activeEventIsPersonal,
				'chat-manager--collapse-btn-center': collapse && !hasUnreadMessages,
			},
		]"
		:default-width="'43%'"
		:collapsedWidth="'90px'"
		:color="'var(--light-2)'"
		:collapsed-padding="'30px 12.5px 0px'"
		:default-padding="'28px 30px 16px 20px'"
		@on-change="onOrgChatCollapse">
		<template
			v-if="chatIsAvailable"
			#header>
			<template v-if="!collapse">
				<PageChatAddAgentOrgChat />

				<PageChatBadges :organizers="getRoom!.organizers" />
			</template>

			<PageChatNumberMessages
				v-if="collapse && hasUnreadMessages"
				:count="countUnreadMessages" />
		</template>

		<template v-if="chatIsAvailable">
			<div
				v-if="collapse"
				class="chat-manager__collapse">
				<PageChatBadges
					:organizers="getRoom!.organizers"
					:column="true" />

				<PageChatAddAgentOrgChat />
			</div>

			<PageChatManagerChatContainer
				v-if="!collapse && activeEventStore.getActiveEvent"
				:room="getRoom!"
				:event="activeEventStore.getActiveEvent"
				:agent_id="activeEventStore.getActiveEvent.agent"
				class="chat-manager__wrapper" />
		</template>

		<span
			v-if="chatIsUnavailable"
			class="chat-manager__chat p4-b">
			Чат недоступен, отсутствует активное событие.
		</span>

		<span
			v-if="activeEventIsPersonal && !collapse"
			class="chat-manager__chat p4-b">
			Чат недоступен, для данного типа события.
		</span>

		<Loader
			v-if="!collapse && fetchIsPending(getRoomFetching) && !activeEventIsPersonal"
			:loader-centered="true" />
	</CollapseMenu>
</template>

<script setup lang="ts">
	import { useUnreadMessages } from "~/composables/useUnreadMessages";
	import { fetchIsPending } from "~/utils/fetch-is-pending";

	const collapse = useState<boolean>("collapse-org-chat", () => true);

	const { roomStore, activeEventStore } = useStores();
	const { getRoom, getRoomFetching } = storeToRefs(roomStore);

	const onOrgChatCollapse = (value: boolean) => {
		const eventCollapses = useState("collapse-event-list");

		if (value && !eventCollapses.value) {
			eventCollapses.value = !eventCollapses.value;
		}
	};

	const countUnreadMessages = useUnreadMessages();
	const hasUnreadMessages = computed(() => countUnreadMessages.value > 0);

	const activeEventIsPersonal = computed<boolean>(() => activeEventStore.getActiveEvent?.event === "Входящее сообщение");
	const chatIsAvailable = computed(() => !activeEventIsPersonal.value && (getRoomFetching.value === "success" || (getRoomFetching.value === "silent" && getRoom.value)));
	const chatIsUnavailable = computed(() => !fetchIsPending(getRoomFetching.value) && !getRoom.value && !collapse.value);

	const orgChatBus = useOrgChatBus();

	orgChatBus.on(async (event) => {
		if (chatIsAvailable && event.data.room_id !== getRoom.value!.id) return;

		await roomStore.fetchEventRoom(true);
	});
</script>

<style lang="scss" scoped>
	@import "chat-manager";
</style>
