import { computed } from "vue";

export const useUnreadMessages = () => {
	const currentOrgId = useUser().userId;
	const { roomStore } = useStores();

	return computed(() => {
		const room = roomStore.getRoom;

		if (!room) return 0;

		const organizers = room.meta.organizers;

		return Object.keys(organizers).reduce((total, orgId) => {
			if (orgId !== currentOrgId) {
				total += organizers[orgId].unread_messages || 0;
			}
			return total;
		}, 0);
	});
};
