<template>
	<Modal
		v-model="modalIsVisible"
		class="remove-agent-org-chat">
		<ModalContent
			:modal-title="'Удаление агентов'"
			:modal-description="agents.length ? '' : 'Агенты отсутствуют'">
			<template #modalBody>
				<DataTable
					v-if="agents.length"
					v-model:selection="selectedAgents"
					:value="agents"
					dataKey="id"
					class="datatable"
					table-class="table table--checkbox"
					paginator
					:always-show-paginator="false"
					:rows="5">
					<Column selectionMode="multiple" />

					<Column
						field="name"
						:style="'min-width: 220px'"
						header="Агент" />

					<Column
						field="organizer.name"
						:style="'min-width: 220px'"
						header="Организатор" />

					<Column
						field="status"
						header="Статус">
						<template #body="{ data: agent }: { data: IAgentRoom }">
							{{ getAgentStatus(agent) }}
						</template>
					</Column>

					<Column
						field="agent_type.name"
						:style="'width: 180px'"
						header="Тип агента" />
				</DataTable>
			</template>

			<template #modalControls>
				<UiButton
					v-if="agents.length"
					:name="'Удалить агентов'"
					:disabled="!selectedAgents.length || fetchIsPending(statusRemovingAgents)"
					:loader="fetchIsPending(statusRemovingAgents)"
					btn-color="black"
					:loader-size="'14'"
					:size="'md'"
					@click="removeAgents" />
			</template>
		</ModalContent>
	</Modal>
</template>

<script setup lang="ts">
	import { $useFetch } from "~/composables/useFetch";
	import { api } from "~/api/api";
	import { fetchIsPending } from "~/utils/fetch-is-pending";
	import { getAgentStatus } from "@/utils/get-agent-status";

	const { modelValue } = defineProps<{ modelValue: boolean }>();

	const emits = defineEmits<{
		(event: "update:modelValue", value: boolean): void;
	}>();

	const modalIsVisible = ref<boolean>(false);

	const { roomStore, modalErrorStore } = useStores();
	const { getRoom } = storeToRefs(roomStore);

	const agents = computed(() => getRoom.value?.agents ?? []);
	const selectedAgents = ref<IAgentRoom[]>([]);
	const selectedAgentsId = computed<string[]>(() => selectedAgents.value.map((agent) => agent.id));

	const {
		data: responseRemovingAgents,
		error: errorRemovingAgents,
		refresh: fetchRemove,
		status: statusRemovingAgents,
	} = $useFetch<IApiResponsePayload<IApiResponseMessage>, TFetchError>(() => api.roomAgents(getRoom.value?.id), {
		method: "DELETE",
		immediate: false,
		watch: false,
		body: {
			agents: selectedAgentsId,
		},
	});

	const removeAgents = async () => {
		await fetchRemove();

		if (errorRemovingAgents.value || !responseRemovingAgents.value?.data.result) {
			modalErrorStore.open(errorRemovingAgents.value?.message || "Ошибка удаления агентов", true);
			return;
		}

		roomStore.fetchEventRoom(true);
		modalIsVisible.value = false;
		modalErrorStore.open("Агенты удалены");
	};

	watch(
		() => modelValue,
		(value) => {
			if (value === modalIsVisible.value) return;

			modalIsVisible.value = value;
		},
	);

	watch(
		() => modalIsVisible.value,
		(value) => {
			if (!value) {
				selectedAgents.value = [];
			}

			emits("update:modelValue", value);
		},
	);
</script>

<style scoped lang="scss">
	@import "remove-agent-org-chat";
</style>
