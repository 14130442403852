import { api } from "~/api/api";
import type { AsyncDataRequestStatus } from "#app";

export const useOrgChat = (
	roomId: string,
	agentId: string,
): {
	org_message: Ref<string>;
	sendMessage(): Promise<void>;
	sendRoomMessageStatus: Ref<AsyncDataRequestStatus>;
	messages: Ref<IApiResponsePayload<IOrgMessage[]> | null>;
	getRoomMessages(): Promise<void>;
} => {
	const org_message = useState(`room-${roomId}-message`, () => "");

	const { data: messages, refresh: getRoomMessages } = $useFetch<IApiResponsePayload<IOrgMessage[]>, TFetchError>(api.roomMessage(roomId), {
		key: `room-${roomId}-messages`,
		method: "GET",
	});

	const sendMessageBody: ComputedRef<IOrgMessageBody> = computed(
		(): IOrgMessageBody => ({
			agent: agentId,
			message: org_message.value,
			type: "chat",
		}),
	);

	const {
		data: sentMessageResult,
		error: sentMessageResultError,
		refresh: sendRoomMessage,
		status: sendRoomMessageStatus,
	} = $useFetch<IApiResponsePayload<IOrgSendMessageRes>, TFetchError>(api.roomMessage(roomId), {
		method: "POST",
		body: sendMessageBody,
		immediate: false,
		watch: false,
	});

	const sendMessage = async (): Promise<void> => {
		if (!agentId) return;

		await sendRoomMessage();

		if (!sentMessageResult.value?.data.result || sentMessageResultError.value) return;

		org_message.value = "";
	};

	return { org_message, sendMessage, sendRoomMessageStatus, messages, getRoomMessages };
};
