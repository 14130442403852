<template>
	<div class="selection">
		<nuxt-icon
			v-if="icon"
			:class="['selection__icon', additionalIconClasses]"
			:name="icon" />

		<v-select
			v-model="value"
			:options="list"
			:placeholder="computedPlaceholder"
			:searchable="searchable"
			:clearable="isClearable"
			class="custom-vselect custom-vselect--sm"
			:class="{ 'custom-vselect--icon': icon }"
			:label="selectLabel"
			:disabled="disabled">
			<template #no-options>Ничего не найдено</template>
		</v-select>
	</div>
</template>

<script setup>
	import { watch, ref, computed } from "vue";
	import vSelect from "@/vendors/vue-select-modified/vue-select-modified";

	const props = defineProps({
		modelValue: [String, Number, Object],
		list: {
			type: Array,
			required: true,
			default: () => [],
		},
		searchable: Boolean,
		icon: String,
		selectLabel: String,
		disabled: Boolean,
		additionalIconClasses: String,
		placeholder: String,
		clearable: {
			type: Boolean,
			default: true,
		},
	});

	const emits = defineEmits(["update:modelValue", "onValueUpdate"]);
	const value = ref(props.modelValue);

	const computedPlaceholder = computed(() => props.placeholder || props.list?.[0]?.name || props.list?.[0] || "");

	const isClearable = computed(() => props.clearable && (value.value?.name ? value.value.name !== props.list?.[0]?.name : value.value !== props.list?.[0]));

	watch(
		() => props.modelValue,
		(newVal) => {
			value.value = newVal ?? props.list[0];
		},
	);

	watch(value, (newVal) => {
		emits("update:modelValue", newVal ?? props.list[0]);
		emits("onValueUpdate", newVal ?? props.list[0]);
	});
</script>

<style lang="scss">
	@import "@/vendors/vue-select-modified/vue-select.css";
	@import "@/assets/scss/global/custom-vue-select/custom-vue-select";
	@import "selection";
</style>
